<template>
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Body-->
    <div class="card-body d-flex flex-column">
      <div class="flex-grow-1 pb-5">
        <!--begin::Info-->
        <h1 style="text-transform: uppercase">{{ pageTitle }}</h1>
        <div class="d-flex align-items-center mb-6">
          <span class="text-muted font-weight-bold font-size-lg flex-grow-1"
            >Kundeninformationen</span
          >
        </div>
        <!--end::Info-->

        <div class="vld-parent">
          <loading
            :active.sync="isLoading"
            :can-cancel="true"
            :is-full-page="true"
          ></loading>
        </div>

        <v-app>
          <v-container>
            <v-row style="margin-top: 25px;">
              <v-col cols="12" class="d-flex justify-end text-center">
                <v-btn
                  class="primary custom_button"
                  large
                  height="50"
                  style="margin-right: 15px;"
                  @click="dialog = true"
                >
                  <v-icon left>mdi-plus</v-icon>
                  Neuen Kunden
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-card-title>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Suchen Sie hier nach einem Kunden"
                    hide-details
                    clearable
                    @keypress="getDataFromApi"
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="clients"
                  :options.sync="options"
                  :server-items-length="totalClients"
                  sort-by="CompanyName"
                  class="table1"
                  :loading="loadingVariable"
                  :search="search"
                  loading-text="Bitte warten... Kunden werden geladen"
                  :items-per-page="-1"
                  :disable-pagination="true"
                >
                  <template v-slot:footer.page-text="items">
<!--                    {{ items.pageStart }} von {{ items.itemsLength }}  Kunde möchte kein Paginator haben -->
                    Gesamtanzahl der Kunden: {{ items.itemsLength }}
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <!--                    <v-tooltip top attach="" dense color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          title="Edit user"
                          class="mr-2"
                          @click="editItem(item)"
                          text="test"
                          style="font-size: 20px;"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-magnify
                        </v-icon>
                      </template>
                      <span>Kundenansicht</span>
                    </v-tooltip>-->
                    <v-tooltip top attach="" dense color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          class="mr-2"
                          @click="toClientItems(item)"
                          text="test"
                          style="font-size: 20px;"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-percent
                        </v-icon>
                      </template>
                      <span>Artikel-Preisverwaltung</span>
                    </v-tooltip>
                    <v-tooltip top attach="" dense color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          title="Edit user"
                          class="mr-2"
                          @click="editItem(item)"
                          text="test"
                          style="font-size: 20px;"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>Editieren</span>
                    </v-tooltip>
                    <v-tooltip top attach="" dense color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          title="Edit user"
                          class="mr-2"
                          @click="deleteClient(item)"
                          text="test"
                          style="font-size: 20px;"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>Löschen</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:no-data>
                    Kein Kunde vorhanden
                  </template>
                  <!--                  <template v-slot:no-data>-->
                  <!--                    <div>Please choose a sender</div>-->
                  <!--                  </template>-->
                </v-data-table>
              </v-col>
            </v-row>

            <v-dialog v-model="dialog" max-width="1200px">
              <!--              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" class="mb-2" v-bind="attrs" v-on="on">
                  Create new client
                </v-btn>
              </template>-->
              <v-card>
                <v-card-title class="m-2 mb-6">
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-form ref="form" lazy-validation>
                      <v-row>
                        <v-col cols="9" sm="9" md="9">
                          <v-text-field
                            v-model="editedItem.CompanyName"
                            label="Name des Unternehmens*"
                            filled
                            auto-grow
                            background-color="#f7f7f7"
                            name="input-7-1"
                            dense
                            clearable
                            :rules="[rules.required]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="9" sm="9" md="9">
                          <v-text-field
                            v-model="editedItem.CompanyName2"
                            label="Namenszusatz*"
                            filled
                            auto-grow
                            background-color="#f7f7f7"
                            name="input-7-1"
                            dense
                            clearable
                          ></v-text-field>
                        </v-col>
                        <v-col cols="9" sm="9" md="9">
                          <v-text-field
                            v-model="editedItem.ExternalID"
                            label="Externe ID Nummer*"
                            filled
                            auto-grow
                            background-color="#f7f7f7"
                            name="input-7-1"
                            dense
                            clearable
                            :rules="[rules.required]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="9" sm="9" md="9">
                          <v-text-field
                            v-model="editedItem.Street"
                            label="Straße*"
                            filled
                            auto-grow
                            background-color="#f7f7f7"
                            name="input-7-1"
                            dense
                            clearable
                            :rules="[rules.required]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" sm="4" md="4">
                          <v-text-field
                            v-model="editedItem.PostalCode"
                            label="PLZ*"
                            filled
                            auto-grow
                            background-color="#f7f7f7"
                            name="input-7-1"
                            dense
                            clearable
                            :rules="[rules.required]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" sm="4" md="4">
                          <v-text-field
                            v-model="editedItem.City"
                            label="Stadt*"
                            filled
                            auto-grow
                            background-color="#f7f7f7"
                            name="input-7-1"
                            dense
                            clearable
                            :rules="[rules.required]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="3" md="3">
                          <v-text-field
                            v-model="editedItem.CountryCode"
                            label="Ländercode*"
                            filled
                            auto-grow
                            background-color="#f7f7f7"
                            name="input-7-1"
                            dense
                            clearable
                            :rules="[rules.required]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="editedItem.ContactPerson"
                            label="Kontaktperson"
                            filled
                            auto-grow
                            background-color="#f7f7f7"
                            name="input-7-1"
                            dense
                            clearable
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="editedItem.InvoiceMail"
                            label="E-Mail Buchhaltung"
                            filled
                            auto-grow
                            background-color="#f7f7f7"
                            name="input-7-1"
                            dense
                            clearable
                            :rules="[rules.email]"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                          <v-text-field
                            v-model="editedItem.ContactMail"
                            label="E-Mail-Kontaktadresse"
                            filled
                            auto-grow
                            background-color="#f7f7f7"
                            name="input-7-1"
                            dense
                            clearable
                            :rules="[rules.email]"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6" md="6">
                          <div v-if="formInvalid" class="fillAllFieldsMessage">
                            {{ formInvalidMessage }}
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              class="mr-4 p-7"
                              color="btn2"
                              @click="close()"
                            >
                              Schließen
                            </v-btn>
                            <v-btn class="p-7" color="primary" @click="save()">
                              Speichern
                            </v-btn>
                          </v-card-actions>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-container>
        </v-app>
      </div>
    </div>
  </div>
</template>
<style>
.v-data-footer__select {
  display: none !important;
}
.custom_button.theme--light.v-btn.v-btn--disabled {
  background-color: lightgray !important;
}
.fillAllFieldsMessage {
  color: red;
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
// eslint-disable-next-line no-unused-vars
import { LOGIN, LOGOUT } from "@/core/services/store/auth.module";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Vue from "vue";
import Swal from "sweetalert2";
Vue.use(Loading);

export default {
  name: "clients",
  components: { Loading },
  data: function() {
    return {
      pageTitle: "Kundenansicht",
      dialog: false,
      loadingVariable: false,
      search: "",
      headers: [
        { text: "Ext.Nr.", value: "ExternalID" },
        { text: "Kundenname", value: "CompanyName" },
        { text: "Kontaktperson", value: "ContactPerson" },
        { text: "Straße", value: "Street" },
        { text: "Stadt", value: "City" },
        { text: "Optionen", value: "actions", sortable: false , width: "120px"}
      ],
      totalClients: 0,
      options: { page: 1 },
      clients: {},
      editedIndex: -1,
      editedItem: {
        id: "",
        ExternalID: "",
        CompanyName: "",
        CompanyName2: "",
        Street: "",
        Street2: "",
        PostalCode: "",
        City: "",
        CountryCode: "",
        ContactPerson: "",
        InvoiceMail: "",
        InternalInvoiceMail: "",
        ContactMail: "",
        VATNumber: ""
      },
      formInvalidMessage: "*Please complete all input fields",
      formInvalid: false,
      rules: {
        fullPage: false,
        required: value => !!value || "Required.",
        formHasErrors: false,
        min: v => (v && v.length >= 8) || "Min 8 characters",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return value ? pattern.test(value) || "Invalid e-mail." : true;
        }
      },
      isLoading: false
    };
  },
  created() {
    this.clients = [];
    this.getDataFromApi();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Overview" }]);
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Kundeninformationen editieren"
        : "Kundeninformationen";
    }
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true
    },
    dialog(val) {
      val || this.close();
    }
  },
  methods: {
    getDataFromApi() {
      this.loadingVariable = true;
      ApiService.query(
          //Paginator wurde ausgeschaltet -> Kundenwunsch
          //"/clients?page=" + this.options.page + "&search=" + this.search
          "/clients?search=" + this.search
      )
        .then(({ data }) => {
          this.loadingVariable = false;
          this.clients = data.clients;
          this.totalClients = data.clients.total;
        })
        .catch(({ response }) => {
          if (response.status === 401) {
            if (response.status === 401) {
              this.loadingVariable = false;
              this.$store
                .dispatch(LOGOUT)
                .then(() => this.$router.push({ name: "login" }));
            }
          }
        });
    },
    deleteClient(client) {
      Swal.fire({
        title: "Kunden löschen",
        text: "Möchten Sie diesen Kunden wirklich löschen?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#dc3545",
        confirmButtonText: "Löschen",
        cancelButtonText: "Abbrechen"
      }).then(invoice => {
        if (invoice.value) {
          this.isLoading = true;
          ApiService.delete("clients/" + client.id)
            .then(() => {
              location.reload();
            })
            .catch(({ response }) => {
              if (response.status === 401) {
                if (response.status === 401) {
                  this.$store
                    .dispatch(LOGOUT)
                    .then(() => this.$router.push({ name: "login" }));
                }
              }
              this.isLoading = false;
            });
        }
      });
    },
    close() {
      this.dialog = false;
      this.formInvalid = false;
      this.formInvalidMessage = "";
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    editItem(item) {
      this.editedIndex = this.clients.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    save() {
      if (this.$refs.form.validate()) {
        let loader = this.$loading.show({
          // Optional parameters
          container: this.fullPage ? null : this.$refs.formContainer,
          canCancel: true,
          onCancel: this.onCancel
        });
        if (this.editedIndex > -1) {
          ApiService.put("clients/update", this.editedItem)
            .then(response => {
              let client = response.data.client;
              if (client) {
                Object.assign(this.clients[this.editedIndex], client);
                this.resetForm();
                this.close();
              }
              loader.hide();
            })
            .catch(({ response }) => {
              this.formInvalidMessage = response.data.message;
              this.formInvalid = true;
              loader.hide();
            });
        } else {
          ApiService.post("clients/add", this.editedItem)
            .then(({ data }) => {
              if (data.client) {
                this.clients.splice(0, 0, data.client);
                this.resetForm();
                this.close();
              }
              loader.hide();
            })
            .catch(response => {
              this.formInvalidMessage = response.data;
              this.formInvalid = true;
              loader.hide();
            });
        }
      } else this.formInvalid = true;
    },
    toClientItems(item) {
      //this.$router.push("/dashboard/schedules");
      this.$router.push({
        name: "clientsitems",
        params: { client_id: item.id, company_client: item },
        pathToRegexpOptions: { strict: true }
      });

      history.pushState({}, null, this.$route.path + "/items/");
    },
    resetForm() {
      this.formInvalid = false;
      for (var key in this.editedItem) {
        this.editedItem[key] = "";
      }
    },
    getHeaders(headingText) {
      return [
        {
          text: "Dynamic heading no. " + headingText,
          align: "left",
          sortable: false,
          value: "name"
        }
      ];
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  }
};
</script>
